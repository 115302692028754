import React, { useEffect, useState } from 'react'
import { Stack, Typography, Button } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'

import Card from '../elements/Card'
import { RESEARCH_TYPES, problemSolvingPhases, scientificPhases } from '../utils/researchConstants'
import Firebase from '../containers/Firebase'
import FirestoreSchool from '../containers/FirestoreSchool'

const PhaseSelection = ({ phases, research, onSelect }) => (
  <Stack spacing={4} maxWidth='md' sx={{ mt: 4 }}>
    <Stack spacing={2}>
      <Typography variant='h5' fontWeight='bold'>
        どのフェーズで悩んでいますか？
      </Typography>
    </Stack>

    <Stack spacing={2}>
      <Typography variant='h6' color='text.secondary'>
        {research.researchType === RESEARCH_TYPES.PROBLEM_SOLVING ? '課題解決型' : '科学研究型'}
      </Typography>
      {phases.map((phase) => (
        <Card
          key={phase.id}
          onClick={() => onSelect(phase)}
          sx={{
            cursor: 'pointer',
            transition: 'all 0.2s',
            '&:hover': {
              transform: 'translateY(-2px)',
              bgcolor: 'background.paper',
              boxShadow: 2,
            },
          }}
        >
          <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={2}>
            <Stack spacing={1}>
              <Typography variant='subtitle1' fontWeight='bold'>
                {phase.title}
              </Typography>
            </Stack>
            <ChevronRightIcon color='primary' />
          </Stack>
        </Card>
      ))}
    </Stack>
  </Stack>
)

const ConcernSelection = ({ phase, onSelect, onBack }) => (
  <Stack spacing={4} maxWidth='md' sx={{ mt: 4 }}>
    <Stack spacing={2}>
      <Typography variant='h5' fontWeight='bold'>
        {phase.title}フェーズでの悩み
      </Typography>
    </Stack>

    <Stack spacing={2}>
      {phase.concerns.map((concern) => (
        <Card
          key={concern.id}
          onClick={() => onSelect(concern)}
          sx={{
            cursor: 'pointer',
            transition: 'all 0.2s',
            '&:hover': {
              transform: 'translateY(-2px)',
              bgcolor: 'background.paper',
              boxShadow: 2,
            },
          }}
        >
          <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={2}>
            <Typography variant='subtitle1' fontWeight='bold'>
              {concern.title}
            </Typography>
            <ChevronRightIcon color='primary' />
          </Stack>
        </Card>
      ))}
    </Stack>

    <Button variant='outlined' onClick={onBack} sx={{ alignSelf: 'center' }}>
      <ChevronLeftIcon />
      フェーズ選択に戻る
    </Button>
  </Stack>
)

const AdviceDisplay = ({ concern, phase, phases, onReset, onBack }) => {
  const currentPhaseIndex = phases.findIndex((p) => p.id === phase.id)
  const hasNextPhase = currentPhaseIndex < phases.length - 1
  const nextPhase = hasNextPhase ? phases[currentPhaseIndex + 1] : null

  return (
    <Stack spacing={4} maxWidth='md' sx={{ mt: 4 }}>
      <Card>
        <Stack spacing={3}>
          <Typography variant='h5' fontWeight='bold'>
            {concern.title}
          </Typography>
          <Stack spacing={2}>
            <Typography variant='h6'>アドバイス</Typography>
            <Typography variant='body1' whiteSpace='pre-wrap' sx={{ lineHeight: 1.8 }}>
              {concern.advice}
            </Typography>
          </Stack>
          <Stack spacing={2} alignItems='center'>
            <Stack direction='row' spacing={2}>
              <Button variant='outlined' color='primary' onClick={onBack}>
                同じフェーズで他の問題を解決する
              </Button>
              {hasNextPhase && (
                <Button
                  variant='outlined'
                  color='primary'
                  endIcon={<ChevronRightIcon />}
                  onClick={() => onReset(nextPhase)}
                >
                  次のフェーズに進む
                </Button>
              )}
            </Stack>
            <Button
              variant='contained'
              component={Link}
              to='/application/form'
              target='_blank'
              sx={{ mt: 2 }}
            >
              探究の専門家に直接相談したい場合はこちら
            </Button>
          </Stack>
        </Stack>
      </Card>
    </Stack>
  )
}

export default () => {
  const navigate = useNavigate()
  const { currentUser } = Firebase.useContainer()
  const firestoreSchool = FirestoreSchool.useContainer()

  const { data: user } = firestoreSchool.useResearch(currentUser.uid)
  const research = user?.research

  const [step, setStep] = useState('phase')
  const [selectedPhase, setSelectedPhase] = useState(null)
  const [selectedConcern, setSelectedConcern] = useState(null)

  useEffect(() => {
    if (user !== undefined && !research?.theme) {
      navigate('/research-themes/settings')
    }
  }, [user, research, navigate])

  if (!user || !research) return null

  const phases =
    research.researchType === RESEARCH_TYPES.PROBLEM_SOLVING
      ? problemSolvingPhases
      : scientificPhases

  const handlePhaseSelect = (phase) => {
    setSelectedPhase(phase)
    setStep('concern')
  }

  const handleConcernSelect = (concern) => {
    setSelectedConcern(concern)
    setStep('advice')
  }

  const handleBack = () => {
    setStep('concern')
  }

  const handleReset = (nextPhase = null) => {
    if (nextPhase) {
      setSelectedPhase(nextPhase)
      setStep('concern')
    } else {
      setStep('phase')
      setSelectedPhase(null)
    }
    setSelectedConcern(null)
  }

  switch (step) {
    case 'phase':
      return <PhaseSelection phases={phases} research={research} onSelect={handlePhaseSelect} />
    case 'concern':
      return (
        <ConcernSelection
          phase={selectedPhase}
          onSelect={handleConcernSelect}
          onBack={handleBack}
        />
      )
    case 'advice':
      return (
        <AdviceDisplay
          concern={selectedConcern}
          phase={selectedPhase}
          phases={phases}
          onBack={handleBack}
          onReset={handleReset}
        />
      )
    default:
      return null
  }
}

// TODO: 診断結果をFirestoreに保存するため、Firestoreの設計を行う。更新と単一取得、全件取得の処理をそれぞれ実装する
// TODO: 次のステップに進むボタンは削除する
// TODO: 診断のトップページに戻るボタンを追加する
