import React from 'react'
import { Link } from 'react-router-dom'
import { Stack, Grid, Typography } from '@mui/material'
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates'

import Card from '../elements/Card'

import logoTransparent from '../assets/logo-transparent.png'
import { HERO_ID, useOverlapDetection } from '../utils/useOverlapDetection'
import LaunchIcon from '@mui/icons-material/Launch'
import { roleOrderObj } from '../containers/AppContainer'
import Firestore from '../containers/Firestore'

export default () => {
  const { currentUserRole } = Firestore.useContainer()
  const [textRef, isOverlapping] = useOverlapDetection(HERO_ID)

  return (
    <Stack spacing={6} maxWidth='md'>
      <Stack spacing={2}>
        <Stack spacing={2}>
          <Typography variant='h5' fontWeight='bold' color='white.main'>
            探究応援プラットフォーム
          </Typography>
          <Typography variant='caption' color='white.main'>
            探究を進める上で参考になる事例を探したり、自分たちの探究を深めるために必要な外部パートナーを探したりしましょう。
          </Typography>
        </Stack>
        <Card>
          <Stack direction='row' spacing={1} mb={2}>
            <TipsAndUpdatesIcon color='text' />
            <Typography fontWeight='bold'>使ってみよう</Typography>
          </Stack>

          <Grid container spacing={2}>
            {sectionList.map(({ to, title, subtitle, color, isUnderConstruction }, i) => (
              <Grid key={i} item xs={12} md={4}>
                <Stack
                  component={isUnderConstruction ? undefined : Link}
                  to={to}
                  spacing={1}
                  p={2}
                  height='calc(100% - 28px)'
                  sx={{
                    backgroundColor: `${color}.main`,
                    borderRadius: 2,
                    position: 'relative',
                    overflow: 'hidden',
                  }}
                >
                  <Typography fontWeight='bold' color='white.main'>
                    {title}
                  </Typography>
                  <Typography variant='caption' color='white.main'>
                    {subtitle}
                  </Typography>
                  <img
                    src={logoTransparent}
                    alt=''
                    style={{
                      position: 'absolute',
                      bottom: 0,
                      right: 0,
                      height: '50%',
                      width: 'auto',
                    }}
                  />
                  {!!isUnderConstruction && (
                    <Stack
                      alignItems='center'
                      justifyContent='center'
                      sx={{
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        right: 0,
                        left: 0,
                        zIndex: 10,
                        backgroundColor: 'rgba(0, 0, 0, 0.45)',
                        mt: '0!important',
                      }}
                    >
                      <Typography
                        variant='h6'
                        color='grey.main'
                        sx={{ transform: 'rotate(-15deg)', opacity: 0.6 }}
                      >
                        Comming soon ...
                      </Typography>
                    </Stack>
                  )}
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Card>
      </Stack>
      <Stack spacing={2} ref={textRef}>
        <Typography
          variant='h6'
          sx={{
            color: isOverlapping && '#fff',
          }}
        >
          使い方マニュアル
        </Typography>
        <Stack gap={3} direction='row' flexWrap='wrap'>
          {manualList
            .filter(({ minUserRole }) => roleOrderObj[minUserRole] <= roleOrderObj[currentUserRole])
            .map(({ title, description, link }) => (
              <Link key={title} to={link} target='_blank'>
                <Card sx={{ width: '280px' }}>
                  <Stack spacing={2}>
                    <Stack
                      direction='row'
                      spacing={2}
                      alignItems='center'
                      justifyContent='space-between'
                    >
                      <Typography variant='h6' fontWeight='bold'>
                        {title}
                      </Typography>
                      <LaunchIcon />
                    </Stack>
                    <Typography variant='body1' color='gray'>
                      {description}
                    </Typography>
                  </Stack>
                </Card>
              </Link>
            ))}
        </Stack>
      </Stack>
    </Stack>
  )
}

const sectionList = [
  {
    to: '/research-themes',
    title: '探究助言を見よう',
    subtitle: 'システムが、探究における悩みフェーズごとの助言を提示してくれます。',
    color: 'info',
  },
  {
    to: '/mentors',
    title: 'メンターを探そう',
    subtitle:
      '探究を外から支えてくれる企業や個人を探すことが出来ます。積極的に外部と繋がり探究を深めましょう。',
    color: 'error',
  },
  {
    to: '/application',
    title: '協力を依頼しよう',
    subtitle: '希望のメンターがいない場合や運営に直接相談したいことがある場合などはこちらから。',
    color: 'success',
  },
]

const manualList = [
  {
    title: '使い方（教員用）',
    description: '教員アカウントについてのマニュアル動画です',
    link: 'https://youtu.be/W5xxCG2r4Yk',
    minUserRole: 'teacher',
  },
  {
    title: '使い方（生徒用）',
    description: '生徒アカウントについてのマニュアル動画です',
    link: 'https://youtu.be/6328nNQ-svo',
    minUserRole: 'student',
  },
  {
    title: '生徒への応援動画',
    description: '探究に取り組む生徒の皆さんへ大人たちからのメッセージです',
    link: 'https://youtu.be/01woaw0inf8',
    minUserRole: 'student',
  },
]
