import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Stack, Typography, Button, Chip } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

import Card from '../elements/Card'
import { RESEARCH_TYPES, CATEGORIES } from '../utils/researchConstants'
import Firebase from '../containers/Firebase'
import Firestore from '../containers/Firestore'
import FirestoreSchool from '../containers/FirestoreSchool'

const ResearchDisplay = ({ research, onEdit }) => (
  <Card>
    <Stack spacing={3}>
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <Typography variant='h6'>あなたの探究テーマ</Typography>
        <Button startIcon={<EditIcon />} onClick={onEdit} sx={{ minWidth: 'auto' }}>
          内容を編集する
        </Button>
      </Stack>

      <Stack spacing={2}>
        <Stack spacing={1}>
          <Typography variant='subtitle2' color='text.secondary'>
            探究タイプ
          </Typography>
          <Typography>
            {research.researchType === RESEARCH_TYPES.PROBLEM_SOLVING ? '課題解決型' : '科学研究型'}
          </Typography>
        </Stack>

        <Stack spacing={1}>
          <Typography variant='subtitle2' color='text.secondary'>
            テーマカテゴリ
          </Typography>
          <Stack direction='row' spacing={1} flexWrap='wrap'>
            {research.categories.map((id) => (
              <Chip
                key={id}
                label={CATEGORIES[research.researchType].find((c) => c.id === id)?.label}
                variant='outlined'
              />
            ))}
          </Stack>
        </Stack>

        <Stack spacing={1}>
          <Typography variant='subtitle2' color='text.secondary'>
            テーマ内容
          </Typography>
          <Typography>{research.theme}</Typography>
        </Stack>
      </Stack>
    </Stack>
  </Card>
)

const DiagnosisPrompt = ({ onDiagnose }) => (
  <Card>
    <Stack spacing={3}>
      <Stack spacing={1}>
        <Typography variant='h6'>
          あなたの探究はどこで詰まってる？お悩み診断フローチャート
        </Typography>
        <Typography variant='body2' color='text.secondary'>
          探究活動における悩みを診断し、解決へのアドバイスを提供します。
          必要に応じて、専門家への相談も可能です。
        </Typography>
      </Stack>

      <Button
        variant='contained'
        endIcon={<ChevronRightIcon />}
        onClick={onDiagnose}
        sx={{ alignSelf: 'center' }}
      >
        診断する
      </Button>
    </Stack>
  </Card>
)

const ConsultationPrompt = () => (
  <Card>
    <Stack spacing={3}>
      <Stack spacing={1}>
        <Typography variant='h6'>専門家に直接相談する</Typography>
        <Typography variant='body2' color='text.secondary'>
          探究テーマに関する専門家に直接相談したい場合は、以下のボタンから相談フォームに進んでください。
        </Typography>
      </Stack>

      <Button
        variant='outlined'
        component={Link}
        to='/application/form'
        target='_blank'
        sx={{ alignSelf: 'center' }}
      >
        専門家に相談する
      </Button>
    </Stack>
  </Card>
)

export default () => {
  const navigate = useNavigate()
  const { currentUser } = Firebase.useContainer()
  const { mySchoolDocData: school } = Firestore.useContainer()
  const firestoreSchool = FirestoreSchool.useContainer()

  const { data: user } = firestoreSchool.useResearch(currentUser.uid)
  const research = user?.research

  useEffect(() => {
    if (user !== undefined && !research?.theme) {
      navigate('/research-themes/settings')
    }
  }, [user, research])

  if (!user || !research) return null

  const handleEdit = () => {
    navigate('/research-themes/settings', { state: { research } })
  }

  const handleDiagnose = () => {
    navigate('/research-themes/diagnosis')
  }

  return (
    <Stack spacing={4} maxWidth='md'>
      <Stack spacing={2}>
        <Typography variant='h5' fontWeight='bold' color='white.main'>
          探究助言を見る
        </Typography>
        <Typography variant='caption' color='white.main'>
          システムが、探究における悩みフェーズごとの助言を提示してくれます。
        </Typography>
      </Stack>

      <ResearchDisplay research={research} onEdit={handleEdit} />
      <DiagnosisPrompt onDiagnose={handleDiagnose} />
      <ConsultationPrompt />
    </Stack>
  )
}
