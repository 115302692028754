import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  Stack,
  Typography,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Chip,
} from '@mui/material'

import Card from '../elements/Card'
import { RESEARCH_TYPES, CATEGORIES } from '../utils/researchConstants'
import Firebase from '../containers/Firebase'
import FirestoreSchool from '../containers/FirestoreSchool'

export default () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { currentUser } = Firebase.useContainer()
  const firestoreSchool = FirestoreSchool.useContainer()

  const initialResearch = location.state?.research || {
    researchType: '',
    categories: [],
    theme: '',
  }

  const [step, setStep] = useState(1)
  const [researchType, setResearchType] = useState(initialResearch.researchType)
  const [selectedCategories, setSelectedCategories] = useState(initialResearch.categories)
  const [theme, setTheme] = useState(initialResearch.theme)

  useEffect(() => {
    if (researchType !== initialResearch.researchType) {
      setSelectedCategories([])
    }
  }, [researchType, initialResearch.researchType])

  const handleComplete = async () => {
    const researchData = {
      researchType,
      categories: selectedCategories,
      theme,
    }

    if (initialResearch.id) {
      await firestoreSchool.updateResearch(currentUser.uid, researchData)
    } else {
      await firestoreSchool.addResearch(currentUser.uid, researchData)
    }

    navigate('/research-themes')
  }

  return (
    <Stack spacing={4} maxWidth='md' sx={{ mt: 4 }}>
      <Stack spacing={2}>
        <Typography variant='h5' fontWeight='bold'>
          探究テーマを設定する
        </Typography>
        <Typography variant='h6'>まずはあなたの探究テーマについて教えてください。</Typography>
      </Stack>

      {step === 1 ? (
        <Card>
          <Stack spacing={3}>
            <Typography variant='h6'>あなたの探究はどちらですか？</Typography>
            <RadioGroup value={researchType} onChange={(e) => setResearchType(e.target.value)}>
              <FormControlLabel
                value={RESEARCH_TYPES.PROBLEM_SOLVING}
                control={<Radio />}
                label={
                  <Stack>
                    <Typography fontWeight='bold'>課題解決型</Typography>
                    <Typography variant='body2' color='text.secondary'>
                      何らかの「課題」を解決することを目的として、調査・分析などを行い、最終的には行動を起こすことを目指すもの（ビジネス・ソーシャル）
                    </Typography>
                  </Stack>
                }
              />
              <FormControlLabel
                value={RESEARCH_TYPES.SCIENTIFIC}
                control={<Radio />}
                label={
                  <Stack>
                    <Typography fontWeight='bold'>科学研究型</Typography>
                    <Typography variant='body2' color='text.secondary'>
                      何らかの「未知」を明らかにすることを目的として、調査・実験などを行い、最終的には科学論文を残すことを目指すもの（アカデミック・サイエンス）
                    </Typography>
                  </Stack>
                }
              />
            </RadioGroup>
            <Button
              variant='contained'
              disabled={!researchType}
              onClick={() => setStep(2)}
              sx={{ alignSelf: 'center' }}
            >
              次へ
            </Button>
          </Stack>
        </Card>
      ) : (
        <Card>
          <Stack spacing={4}>
            <Stack spacing={2}>
              <Typography variant='h6'>探究テーマについて</Typography>
              <Typography variant='subtitle1'>Q1. 探究テーマのカテゴリ（2つまで）</Typography>
              <Stack direction='row' spacing={1} flexWrap='wrap' useFlexGap>
                {CATEGORIES[researchType].map((category) => (
                  <Chip
                    key={category.id}
                    label={category.label}
                    onClick={() => {
                      if (selectedCategories.includes(category.id)) {
                        setSelectedCategories((prev) => prev.filter((id) => id !== category.id))
                      } else if (selectedCategories.length < 2) {
                        setSelectedCategories((prev) => [...prev, category.id])
                      }
                    }}
                    color={selectedCategories.includes(category.id) ? 'primary' : 'default'}
                    variant={selectedCategories.includes(category.id) ? 'filled' : 'outlined'}
                    sx={{ mb: 1 }}
                  />
                ))}
              </Stack>
            </Stack>

            <Stack spacing={2}>
              <Typography variant='subtitle1'>Q2. 具体的なテーマについて教えてください</Typography>
              <TextField
                multiline
                rows={4}
                value={theme}
                onChange={(e) => setTheme(e.target.value)}
                placeholder='テーマの詳細を入力してください'
              />
            </Stack>

            <Stack direction='row' spacing={2} justifyContent='center'>
              <Button variant='outlined' onClick={() => setStep(1)}>
                戻る
              </Button>
              <Button
                variant='contained'
                disabled={selectedCategories.length === 0 || !theme}
                onClick={handleComplete}
              >
                完了する
              </Button>
            </Stack>
          </Stack>
        </Card>
      )}
    </Stack>
  )
}
