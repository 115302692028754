export const RESEARCH_TYPES = {
  PROBLEM_SOLVING: 'problemSolving',
  SCIENTIFIC: 'scientific'
}

export const CATEGORIES = {
  [RESEARCH_TYPES.PROBLEM_SOLVING]: [
    { id: 'environment', label: '環境' },
    { id: 'energy', label: 'エネルギー' },
    { id: 'disaster', label: '防災' },
    { id: 'healthcare', label: '医療・健康・福祉' },
    { id: 'education', label: '教育' },
    { id: 'society', label: '政治・社会・法' },
    { id: 'regional', label: '地域創生' },
    { id: 'international', label: '国際' },
    { id: 'business', label: 'ビジネス・経済' },
    { id: 'it', label: 'IT・AI' },
    { id: 'sns', label: 'SNS・マーケ' },
    { id: 'manufacturing', label: 'モノづくり・製造' },
    { id: 'mechanical', label: '機械・化学' },
    { id: 'food', label: '食品' },
    { id: 'retail', label: '小売' },
    { id: 'living', label: '生活用品・日用品' },
    { id: 'transport', label: '交通' },
    { id: 'architecture', label: '建築・空間設計' },
    { id: 'tourism', label: '観光・インフラ' },
    { id: 'apparel', label: 'アパレル' },
    { id: 'art', label: 'アート' },
    { id: 'entertainment', label: 'エンタメ・ゲーム' },
    { id: 'sports', label: 'スポーツ' },
    { id: 'space', label: '宇宙' },
    { id: 'animal', label: '動物' }
  ],
  [RESEARCH_TYPES.SCIENTIFIC]: [
    { id: 'physics', label: '物理・化学' },
    { id: 'biology', label: '生物学' },
    { id: 'earth', label: '地球・宇宙科学' },
    { id: 'engineering', label: '工学' },
    { id: 'medical', label: '医学・健康科学' },
    { id: 'agriculture', label: '農学・環境科学' },
    { id: 'information', label: '情報科学・データサイエンス' },
    { id: 'materials', label: '材料・エネルギー科学' },
    { id: 'economics', label: '経済・政治学' },
    { id: 'psychology', label: '心理学・社会学' },
    { id: 'law', label: '法学・人類学' },
    { id: 'history', label: '歴史学' },
    { id: 'philosophy', label: '哲学・倫理学' },
    { id: 'linguistics', label: '言語・文学・宗教学' }
  ]
}

// 課題解決型のフェーズと悩み
export const problemSolvingPhases = [
  {
    id: 1,
    title: 'テーマを設定する',
    concerns: [
      {
        id: 'ps-1-1',
        title: 'テーマが決まらない',
        advice: 'テーマの決め方にはいくつかのやり方があります。\n・疑問ベース：生活の中で「なぜ？」と思ったことから決める\n・課題ベース：社会や地域の課題から決める\n・興味ベース：興味のあるジャンルから決める\n\nどの決め方でも大切なのは、まず書き出すこと。頭の中であれこれ考えるのではなく、まずは思いつくままに書き出してみましょう。\nまた、インターネットで気になるワードを打ち込み検索してざっと情報を眺めたり、実際に街に出て現場を観察したりといった方法も効果的です。',
      },
      {
        id: 'ps-1-2',
        title: 'テーマ自体は決まったが漠然としている',
        advice: 'テーマに出てくる言葉が曖昧なことが多いです。例えば「フードロス」であれば「家庭での食品廃棄」なのか「家庭に届くまでの食品廃棄」なのかなど、曖昧な言葉を細分化してみましょう。\n細分化が難しい場合は、テーマに設定したキーワードをネットで検索し、出てくる様々な情報を見ながら、大テーマの中にはどういった小テーマが含まれるのかを知ると良いです。\n\nまた、先生や周りの生徒に意見を求めることも重要です。',
      },
      {
        id: 'ps-1-3',
        title: 'このテーマでいいかわからない',
        advice: 'テーマが漠然・曖昧としている場合は、曖昧な言葉を細分化しましょう。\nそのテーマに取り組むべきか決断できない場合や、いくつかの選択肢で迷っている場合は、「自分たちがわくわくするか」「取り組む意義がありそうか」など、判断基準を設けると良いです。\n\nまた、テーマはある程度決まれば、次のフェーズに進むしかありません。次のフェーズで情報収集や課題分析を進める中で見えてくるものもあるので、ある程度固まったら自信をもって次に進んでみましょう。',
      },
    ],
  },
  {
    id: 2,
    title: '情報を収集する',
    concerns: [
      {
        id: 'ps-2-1',
        title: '何を調べればいいかわからない',
        advice: 'まずはざっくりとした情報を二次調査でおさえましょう。二次調査とはインターネット上の記事、政府・企業の統計データ、書籍など、世の中に公開されている情報です。テーマとなっているキーワードで検索などをかけ、様々な情報にざっと目に触れることで、自分たちのテーマに対する「大まかな地図」を頭に入れましょう。\n二次調査はスピード勝負です。情報の信頼性には気を付けつつですが、量重視でとにかく多くの情報に触れましょう。\n次に、ある程度情報が集まったら、5W1Hなどを駆使して、まだ得られていない情報は何かを整理しましょう。まだ得られていない情報が二次調査では得られなさそうだと判断したら、次のフェーズです。',
      },
      {
        id: 'ps-2-2',
        title: 'ネットで調べてもなかなか欲しい情報が手に入らない',
        advice: 'まずは調査方法や検索キーワードを見直しましょう。\n次に、「自分たちが今どんな情報は得られていて、何がまだわかっていないのか」を整理。そして、先生にも相談しながら、その「まだわかっていないこと」は今の二次調査を続けていて得られそうなのかの見切りをつけましょう。\n二次調査の限界点まで来たら、次は探究の要、一次調査です。一次調査はアンケートやインタビュー、フィールドワークなど、自分たちが直接情報を取得する方法です。ここで質の高い情報を得られるかどうかが、良い探究活動を出来るかの鍵となってきます。',
      },
      {
        id: 'ps-2-3',
        title: 'どんな一次調査をすればいいか分からない',
        advice: '「広く浅く」ならアンケート、「狭く深く」ならインタビューです。全体的な動向、傾向などを把握したい場合はアンケートを取り、物事や課題の背景・理由を深く知りたい場合は、少ない人数へじっくりと聞くインタビューや、現地を観察するフィールドワークが良いです。',
      },
      {
        id: 'ps-2-4',
        title: '具体的なインタビューやフィールドワーク先が見つからない',
        advice: '先生にも相談しながら、まずは自分たちで調べてみましょう。\nそれでも見つからない場合は、私たちe-donutsに相談してください。\n（以下のボタンから↓）',
      },
      {
        id: 'ps-2-5',
        title: 'インタビューやフィールドワークで何を聞けばいいかわからない',
        advice: '「自分たちが今わかっていないこと」を基に、質問内容や調査内容を事前に組み立てて臨むと良いでしょう。\n特に、インターネット調査やアンケート調査などで得られた結果に対し、「なぜそうなるのか」と感じたことがあれば、それらはインタビューなどで掘り下げる重要な質問となり得ます。\n\nまた、実際にインタビューをする際には、一問一答で終わるのではなく、相手の方の回答に対し「なぜ」「どうやって」といった掘り下げをするようにしましょう。',
      },
      {
        id: 'ps-2-6',
        title: 'アンケートでどんな質問項目を入れたらいいかわからない',
        advice: '「この質問で本当に知りたいことにたどり着けるのか？」と確認しながら質問項目をつくりましょう。\n例えば、「運動が好きですか？」という質問への答えでは、漠然とした回答しか得られません。「週に何時間運動していますか」「具体的にどういった運動をしていますか」といった質問に分解することで、より実態を把握できます。\n選択式の質問だけでなく、「それはなぜですか？」といった記述式の質問も入れると効果的です。\n\nまた、身の回りの生徒に試験的に回答してもらい、分かりにくい・答えにくい質問がないか確認してもらうことも良いかもしれません。',
      },
    ],
  },
  {
    id: 3,
    title: '課題を分析する',
    concerns: [
      {
        id: 'ps-3-1',
        title: 'インタビューやフィールドワークで情報を得たが、ここからどう分析・解釈していいかわからない',
        advice: 'まずは最初に立てた「何を知りたかったのか」という調査の目的に立ち返りましょう。調査結果を見て、「目的に関する何が読み取れるか」を考えます。\n整理の仕方としては、インタビューやフィールドワークでのメモを見て、内容の塊ごとにグルーピングすることで、それぞれの内容が何についての話なのかを理解しましょう。\nそして、自分たちが取り組みたい課題に対する「なぜ」や「どうやって」を整理した内容から読み取れるとベストです。\nまた、複数の方にインタビューを行った場合で、意見が食い違っている部分がある時は、「なぜそこに差異が生じているのか」を考察することも気づきのきっかけになります。',
      },
      {
        id: 'ps-3-2',
        title: 'アンケート結果を集めたが、ここからどう分析・解釈していいかわからない',
        advice: 'まずは最初に立てた「何を知りたかったのか」という調査の目的に立ち返りましょう。調査結果を見て、「目的に関する何が読み取れるか」を考えます。\n次に、結果集計をもとに表やグラフを作り、データの傾向を掴みます。回答の多い選択肢や、平均値・中央値・ばらつきなどを見て、「データから何が言えるか」という示唆を出してみましょう。\nさらに深く分析するには、グループごとのデータを見ることが重要です。年齢・性別・所属など属性ごとの傾向を分析すると、より詳しく読み取れます。\nまた、自由記述の欄もしっかりと見ることが大切です。',
      },
      {
        id: 'ps-3-3',
        title: '課題分析が浅いと言われたが、どう深掘りしていけばいいかわからない',
        advice: '大抵の場合、「なぜ」の深掘りが足らないことが多いです。\n例えば、「商店街にヒトが来ない」という課題を扱っている場合、調査から「大型商業施設に客が取られている」と分かったとします。ここで解決策に飛びついてはいいアイデアは生まれません。「なぜ大型商業施設に取られているのか？施設に魅力的な施設があるのか、認知度の問題なのか、はたまた他の要因なのか？」といった「なぜ」の考察を繰り返すことで、真の課題にたどり着ければ、大人に負けない課題解決策が見えてくるはずです。',
      },
    ],
  },
  {
    id: 4,
    title: '課題解決策を考える',
    concerns: [
      {
        id: 'ps-4-1',
        title: '具体的なアイデアが出てこない',
        advice: '世の中にある「良いアイデア」は無数の「没アイデア」の上にあります。まずは、量重視でブレストをしてみましょう。その際大事なのは「批評は後回し」という点です。出てきたアイデアへの批判や評価は後回しで、まずはアイデアをたくさん出してみましょう。\n\nまた、調査や分析が不十分でアイデアが出てこないと感じた場合は、先生にも相談しながら前のフェーズに戻ってみるのも一手です。',
      },
      {
        id: 'ps-4-2',
        title: 'どのアイデアをするべきか決め切れない',
        advice: '出てきたアイデアを並べて、評価軸を決めて採点してみましょう。例えば、「効果・意義」「コスト・実現可能性」「独自性・オリジナリティ」などの観点がおすすめです。\n\n注意すべきは、「コスト・実現可能性を気にしすぎない」ということです。到底実現できないアイデアは難しいですが、皆さんが思っているより、工夫次第で実現できることも多いです。\n効果・意義や、独自性があるアイデアは積極的に採用し、「どうやったらそれが少しでも実現できるか」を考えるようにしましょう。',
      },
      {
        id: 'ps-4-3',
        title: '自分たち中高生に出来るアイデアかどうかわからない',
        advice: 'まず、理想として実現したいアイデアを固めたうえで、理想までのステップを分けて考えましょう。\n・例）アプリ開発：設計図の手書き→無料ツールを活用しての試作→本格的な開発\n・例）製品開発：設計図の手書き→3Dプリンターや身近なモノでの試作→企業と連携しての開発\n・例）イベント企画：企画書の作成→校内でのお試し実施→地域での本格実施\n上記のようにステップを分けて、まずは第一ステップを進めてみると良いです。\n\nまた、具体的な方法がわからない、外部と連携したい、設備やお金が足らない、といったことがあれば、私たちe-donutsも積極的に頼ってください。\n（以下のボタンから↓）',
      },
      {
        id: 'ps-4-4',
        title: '自分たちで出したアイデアが既に世の中の誰かがやっていることで、ここからどうしていいかわからない',
        advice: 'どんな業界・領域でも、自分たちが思いついたアイデアは既に誰かがやっていることの方が普通です。むしろ、社会・地域の大人たちが取り組んでいるアイデアに自力でたどり着けた自分たちをまずは褒めましょう。\nその上で、自分たちのオリジナリティを出すには、以下のようなコツがあります。\n・出てきた既存のアイデアを見て、改善できそうなことや変えられそうなことを考える\n・自分たちだから知っている情報や、自分たちの方がアプローチしやすい人や物事を活用できないか考える\n・全く違う領域の何かを掛け合わせることで、面白いアイデアが生まれないか考える',
      },
    ],
  },
  {
    id: 5,
    title: '課題解決策を実行する',
    concerns: [
      {
        id: 'ps-5-1',
        title: 'アイデアを立てたけど、ここからどうやって実行していけばいいかわからない',
        advice: 'まず、理想として実現したいアイデアを固めたうえで、理想までのステップを分けて考えましょう。\n・例）アプリ開発：設計図の手書き→無料ツールを活用しての試作→本格的な開発\n・例）製品開発：設計図の手書き→3Dプリンターや身近なモノでの試作→企業と連携しての開発\n・例）イベント企画：企画書の作成→校内でのお試し実施→地域での本格実施\n上記のようにステップを分けて、まずは第一ステップを進めてみると良いです。\n\nまた、具体的な方法がわからない、外部と連携したい、設備やお金が足らない、といったことがあれば、私たちe-donutsも積極的に頼ってください。\n（以下のボタンから↓）',
      },
      {
        id: 'ps-5-2',
        title: '実行するのにお金や設備が足らない',
        advice: 'まずは自分たちができるステップを考えつつ、その先に進めるには何が必要かを具体的に整理しましょう。\nその上で、困ったことがあれば私たちに相談してください。\n（以下のボタンから↓）',
      },
      {
        id: 'ps-5-3',
        title: '実行するのに技術やノウハウが足らない',
        advice: 'まずは自分たちができるステップを考えつつ、その先に進めるにはどういったスキルや情報が必要かを具体的に整理しましょう。\nその上で、困ったことがあれば私たちに相談してください。\n（以下のボタンから↓）',
      },
    ],
  },
];

// 科学研究型のフェーズと悩み
export const scientificPhases = [
  {
    id: 1,
    title: 'テーマを設定する',
    concerns: [
      {
        id: 'sr-1-1',
        title: 'テーマが決まらない',
        advice: 'まずは自分の興味・関心分野を幅広く洗い出しましょう。日常生活で「なぜ？」と感じたこと、ニュースで話題になっている課題、教科書や先行研究で読んだ分野などをキーワードとしてリストアップします。そこから「この分野の中で、自分が特に面白いと思う現象は何か？」と問いを立て、関心が最も強いものを数点に絞り込んでみてください。また、他の人（友人、教員、家族）に「自分が今気になっていること」を話し、その反応やアドバイスを参考にすると意外なテーマが浮かぶ場合もあります。\n\n頭の中であれこれ考えるのではなく、まずは思いつくままに書き出したり、話したりしてみましょう。',
      },
      {
        id: 'sr-1-2',
        title: 'テーマ自体は決まったが漠然としている',
        advice: 'すでに決まったテーマについて「その中のどんな要素が特に気になるか」「対象となる現象はいつ、どこで、どのように起きているか」といった要素を細分化しましょう。たとえば「環境汚染」ではなく「特定の川の水質変化」や「ある地域の農作物への大気汚染の影響」など、対象を具体的な時空間や現象に落とし込むことで、テーマに輪郭を与えられます。\n\n細分化が難しい場合は、テーマに設定したキーワードをインターネットや論文サイトで検索し、出てくる様々な情報を見ながら、大テーマの中にはどういった小テーマが含まれるのかを知ると良いでしょう。',
      },
      {
        id: 'sr-1-3',
        title: 'このテーマでいいかわからない',
        advice: 'テーマが漠然・曖昧としている場合は、曖昧な言葉を細分化しましょう。\nそのテーマに取り組むべきか決断できない場合や、いくつかの選択肢で迷っている場合は、「自分たちがわくわくするか」「取り組む意義がありそうか」など、判断基準を設けると良いです。\nまた、テーマはある程度決まれば、次のフェーズに進むしかありません。次のフェーズで先行研究の調査や分析を進める中で見えてくるものもあるので、ある程度固まったら自信をもって次に進んでみましょう。',
      },
    ],
  },
  {
    id: 2,
    title: '先行研究を調査する',
    concerns: [
      {
        id: 'sr-2-1',
        title: '先行研究の調べ方が分からない',
        advice: 'Google Scholar、CiNii、学会誌や図書館のデータベースを使い、テーマに関連するキーワードで検索します。国内研究だけでは十分な情報が得られないことも多いため、翻訳ツールを使いながら英語で検索することも重要です。また、検索ワードについては、専門用語・一般用語・類似用語などを変えながら複数試し、結果が増えない場合は範囲を広げたり、関連分野に近いキーワードを試してみてください。',
      },
      {
        id: 'sr-2-2',
        title: '先行研究が見つからない',
        advice: '日本語で出ない場合は、英語での検索にも挑戦しましょう。\nまた、言葉の意味が広すぎる場合は、言葉をより具体的にして検索してみましょう。\nー例）「廃棄物 化粧品 活用」ではなく「コーヒーかす 化粧品 活用」など\nー例）「音楽の発展」ではなく「ロックの発展」や「ジャズの社会的影響」など\n反対に、具体的すぎるワードを使用している場合は、逆により一般化されたワードを使って検索してみましょう。\nー例）「風力タービン 発電効率」ではなく「再生可能エネルギー 発電効率」など\nー例）「アマゾン川 魚 生態」ではなく「熱帯地域 魚 生態」など',
      },
      {
        id: 'sr-2-3',
        title: '見つかりはしたが、どの研究を読むべきかわからない',
        advice: 'タイトルと要約（アブストラクト）を精読し、自分のテーマとの関連度を判断してください。「自分が知りたい現象を直接扱っているか」「研究手法や対象が近いか」などの基準を作り、最も関連度の高い文献から順に読むと効率的です。また、先行研究の参考文献一覧を見ると、関連する重要な研究がまとめて分かることもあります。',
      },
    ],
  },
  {
    id: 3,
    title: '問いを立てる',
    concerns: [
      {
        id: 'sr-3-1',
        title: 'どんな問いを立てればいいかわからない',
        advice: '文系、理系問わず、どういった研究でも、自分たちなりの「まだ明らかにされていないことへの問い」を立てることが研究活動の核です。\n先行研究内の結論や研究の限界に関する部分に注目し、「まだ明らかにされていない部分」を見つけていきます。例えば、廃棄物から美容成分を抽出することに成功した研究があったとしても、より効率的な抽出方法が何かということについての研究は不十分であるかもしれません。\nまた、特定の環境下である生物実験に成功していても、別の環境下で同様の結果が得られるかは判明していないかもしれません。',
      },
      {
        id: 'sr-3-2',
        title: '問いを立てたが、これでいいかわからない',
        advice: '立てた問いがあいまいでないか確認します。「〇〇は△△にどんな影響を与えるのか？」などといった形で、結果を検証できるような問いになっているかをチェックします。\nまた、対象となるヒトやモノはできるだけ具体的な表現にしましょう。「環境にやさしい」「活性化」など、日常的に使う単語のなかにも意味が曖昧なものが溢れています。使う言葉が具体的に何を意味しているのかの解釈にずれが生じていないか、チェックすることが重要です。',
      },
      {
        id: 'sr-3-3',
        title: '問いの候補が複数あって、どうしたらいいかわからない',
        advice: '複数の問いを比較する際、研究資源（時間、道具、協力者）の制約や、期待できる成果の大きさ、独自性・新規性などの観点から優先順位をつけます。もっとも魅力的、かつ実現性の高い問いを最終的に選択しましょう。迷う場合は、教員や仲間に問いそれぞれの意義や実行可能性を相談して、客観的なフィードバックを得ると良いです。\nただし、「実現性を気にしすぎない」ことも重要です。一見難しく思える問いも、工夫すれば取り組めるかもしれないので、研究の意義・楽しさ・独自性などを観点として重視しましょう。',
      },
    ],
  },
  {
    id: 4,
    title: '問いに対する仮説を立てる',
    concerns: [
      {
        id: 'sr-4-1',
        title: '仮説の立て方がわからない',
        advice: '自分たちが立てた問いに対する「仮の答え・予想」として、いくつかの仮説の候補を書き出してみましょう。\n仮説は「もし○○ならば、△△だろう」という予測文で示します。先行研究や自分の直感、観察結果を元に、因果関係や相関関係を推定しましょう。\n\nまた、研究によっては議論だけでは仮説が立てづらいものもあります。そういった場合は、小さな実験を行い、その結果を基に仮説を立てるというアプローチも有効です。',
      },
      {
        id: 'sr-4-2',
        title: '仮説は立てたが、これでいいかわからない',
        advice: '立てた仮説が曖昧すぎないか、条件や対象がはっきりしているかを再点検します。また、検証可能性があるかどうかも重要です。実験や観察でデータが得られそうか、統計分析で結果が裏付けられそうかを考慮し、必要に応じて仮説を言い換えたり、より具体的な条件設定を行いましょう。',
      },
    ],
  },
  {
    id: 5,
    title: '仮説を検証する',
    concerns: [
      {
        id: 'sr-5-1',
        title: '仮説の検証の仕方がわからない',
        advice: '実験、観察、統計解析、シミュレーションなど、問いと仮説に適した手法を選びます。例えば、実験的アプローチが可能なら、条件を操作し、結果を測定する実験計画を立てます。観察研究なら、サンプルサイズや観察期間を明確に定め、客観的なデータが集まる方法を考えます。また、参考文献から用いられている手法を学んだり、指導者に相談することも有効です。',
      },
      {
        id: 'sr-5-2',
        title: '検証方法は決めたが、具体的な手段に問題がある',
        advice: '使用する器具や分析ソフト、観察場所・時間帯が適切か再点検します。もし不足があれば、学校内の環境・器具で代用できないかを活用できないかを探りましょう。\n校内で難しい場合は、積極的に私たちe-donutsにも相談してみてください。\n（以下のボタンから↓）',
      },
      {
        id: 'sr-5-3',
        title: '検証したが、仮説通りの結果が得られなかった',
        advice: '「仮説と違うはチャンスのサイン」です。通常仮説通りの結果が得られることの方が少なく、仮説とどう結果が異なっていたのかを見極め、「なぜ異なっていたのか」「なぜそうした結果になったのか」を考察することが最も重要です。\n考察を基に、問いを変えるべきであれば問いを変え、問いはそのままで検証方法を工夫すべきであれば方法の見直しを図りましょう。',
      },
      {
        id: 'sr-5-4',
        title: '検証したところ、仮説通りの結果が得られた',
        advice: 'まずは、本当に仮説通りの結果なのかを見直します。さらなる裏付けのために、異なる条件での再検証を行い再現性を確かめます。\nまた、結果からの考察が重要です。結果を論理的に整理し、他の研究者の知見と比較して考察を深めます。より広い文脈の中で仮説と検証結果を位置づけ、どのような新規性・貢献があるかをまとめましょう。',
      },
      {
        id: 'sr-5-5',
        title: '検証した結果、自分たちが何をしたいかわからなくなった',
        advice: '「何を明らかにしたいはずだったのか」を再確認します。最初の問題意識や問いに戻り、得られた結果がその問いにどの程度答えを与えているか評価します。もし行き詰まったら、結果から派生する新たな疑問を拾い、それを新たな問いや次の仮説として展開していくことも考えましょう。',
      },
    ],
  },
];
